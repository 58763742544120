<template>
  <div>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Documentos pendientes de firmar
    </div>

    <v-data-table
      :headers="headers"
      :items="docsPtesFirmar"
      :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
      :options.sync="options"
      :server-items-length="totalDocs"
      :loading="loading"
      dense
      style="overflow: scroll"
      mobile-breakpoint="0"
      class="elevation-1"
      @click:row="seleccion"
      >
    </v-data-table>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  import router from "../router";

  export default {
    computed:{
        ...mapState(['empId', 'urlRaiz', 'perId']),
        ...mapState('DocumentosFirmar', ['entidadActual'])
    },
    data () {
      return {
        totalDocs: 0,
        docsPtesFirmar: [],
        loading: true,
        options: {itemsPerPage: 50},
        headers: [
          { text: 'Tipo', sortable: false, value: 'destipo' },
          { text: 'Datos del documento', sortable: false, value: 'datosdoc' }
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
            .then(data => {
              this.docsPtesFirmar = data.items
              this.totalDocs =  data.total
            })
        },
        deep: true,
      },
    },
    mounted () {
      this.getDataFromApi()
        .then(data => {
          this.docsPtesFirmar = data.items
          this.totalDocs = data.total
        })
    },
    methods: {
      ...mapMutations('DocumentosFirmar', ['entidadActualSet']),
      seleccion(itm){ 
        this.entidadActualSet(itm);
        router.push('/documento-firmar');
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve) => {
          const { sortBy, sortDesc } = this.options

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;
        
        var controllerParameters = {
                Action: "GET_DOCS_PTES_FIRMAR_LIST",
                NumRegsPag: NumRegsPag,
                NumPag: NumPag,
                PerId: this.perId
            } ;       

      var AuthToken = localStorage.getItem('token');
      axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            let items = result.data.EntsList;
            const total = result.data.EntsTotCount;

          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          setTimeout(() => {
            this.loading = false
            resolve({
              items,
              total,
            })
          }, 1000)
        });
      })
    },
  },
}
</script>
<style>
  .col_para {
    min-width: 250px;
  }
</style>